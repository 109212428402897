<template>
  <div class="container">
    <header>
      <h2>{{ currentUser.displayName }}</h2>
    </header>

    <div v-if="currentUser.isDoctor">
      <h5>Sobre mí:</h5>

      <!-- <p>{{ currentUser.description }}</p> -->
      <editor-content :editor="editor" />
      <!-- <h5>Listado de publicaciones</h5> -->
    </div>
    <div v-else>
      <small>{{ currentUser.gender ? "Mujer" : "Hombre" }}, </small>
      <small>{{ getYears(currentUser.birthDate) }} años</small>
      <div class="userInfo">
        <div><b>Fecha de Nacimiento:</b> {{ currentUser.birthDate }}</div>
        <div><b>Correo Electrónico:</b> {{ currentUser.email }}</div>
        <div><b>Telefono de Contacto:</b> {{ currentUser.phone }}</div>
        <div>
          <b>Antecedentes personales:</b>
          <small
            v-for="(histText, histind) in currentUser.medicalHistory"
            :key="'histtext_' + histind"
          >
            {{ histind > 0 ? "," + histText : histText }}
          </small>
        </div>
        <div>
          <b>Autorizo recibir notificaciones por:</b>
          <div>
            <div class="switchDisabled">
              <vs-switch
                :success="currentUser.enableMail"
                disabled
                v-model="currentUser.enableMail"
              >
                <template #off>
                  <i class="fas fa-ban"></i>
                </template>
                <template #on>
                  <i class="fas fa-envelope"></i>
                </template>
              </vs-switch>
              <div>Correo Electrónico</div>
            </div>
            <div class="switchDisabled">
              <vs-switch
                :success="currentUser.enablePush"
                disabled
                v-model="currentUser.enablePush"
              >
                <template #off>
                  <i class="fas fa-bell-slash"></i>
                </template>
                <template #on>
                  <i class="fas fa-bell"></i>
                </template>
              </vs-switch>
              <div>Notificaciones en la App</div>
            </div>

            <!-- </vs-button> -->

            <!-- <template v-if="currentUser.enablePush" #icon>
                <i class="fas fa-bell"></i>
              </template>
            </vs-checkbox> -->
          </div>
          <div></div>
        </div>
      </div>
    </div>

    <div v-if="isExternal">
      <vs-button size="mini" block type="flat" @click="onEdit">
        Editar
      </vs-button>
    </div>

    <div v-if="!currentUser.isDoctor" style="margin-top:40px">
      <h4>Resumen de Intervenciones</h4>
      <div style="margin-top:40px">
        <vs-select
          label="Intervención"
          placeholder="Selecciona Intervención"
          v-model="selectedIntervention"
          @change="loadChart"
        >
          <vs-option
            v-for="(inter, i) in interventions"
            :key="'options_' + i"
            :label="inter.name"
            :value="inter.id"
          >
            {{ inter.name }}
          </vs-option>
        </vs-select>
        <br />
        <h6 v-if="selectedIntervention !== ''">Resultados</h6>
        <vs-table v-if="selectedIntervention !== ''">
          <template #thead>
            <vs-tr>
              <vs-th>
                Intervención
              </vs-th>
              <vs-th>
                Fecha
              </vs-th>

              <vs-th
                v-for="(res, i) in filteredIntervention[0].results"
                :key="'th_' + i"
              >
                {{ res.name }}
              </vs-th>
            </vs-tr>
          </template>
          <template #tbody>
            <vs-tr :key="i" v-for="(tr, i) in filteredIntervention" :data="tr">
              <vs-td>
                {{ tr.name }}
              </vs-td>
              <vs-td>
                {{ tr.surgeryDate }}
              </vs-td>
              <vs-td
                v-for="(result, ind) in tr.results"
                :key="'tr_' + i + '_' + ind"
              >
                {{
                  result.points_dr
                    ? result.points + result.points_dr
                    : result.points
                }}
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
        <line-chart
          v-if="loaded && selectedIntervention !== ''"
          :chartData="datacollection"
          :options="this.options"
        />
        <br />
      </div>
    </div>

    <vs-dialog width="90vw" not-center v-model="showDoctor">
      <template #header>
        <h4 class="not-margin">
          <b>Dr. {{ currentUser.displayName }}</b>
        </h4>
      </template>
      <div class="avatar">
        <b-form-file
          ref="avatarInput"
          class="inputAvatar"
          :state="Boolean(true)"
          accept="image/*"
          @change="onUploadFile($event, 'dr')"
        />
        <vs-avatar :loading="imgLoading" badge size="150">
          <img
            ref="avatarImageSrc"
            :src="
              currentUser.imageUrl ||
                'https://image.freepik.com/vector-gratis/doctor-icono-o-avatar-blanco_136162-58.jpg'
            "
            alt=""
          />
          <template #badge>
            <vs-button icon @click="onChangeImg($event, 'dr')">
              <i class="fas fa-pencil-alt"></i>
            </vs-button>
          </template>
        </vs-avatar>
      </div>

      <div class="con-content" style="padding: 1rem 0; text-align: justify;">
        <!-- <textarea
          v-model="description"
          rows="5"
          style="width: 100%;"
          placeholder="Escribe aquí algo sobre tí:"
        /> -->
        <editor-menu-bar
          :editor="editor"
          v-slot="{ commands, isActive, getMarkAttrs }"
        >
          <div class="menubar">
            <vs-button-group>
              <vs-button
                :class="{ 'is-active': isActive.bold() }"
                @click="commands.bold"
              >
                <i class="fas fa-bold"></i>
              </vs-button>
              <vs-button
                :class="{ 'is-active': isActive.italic() }"
                @click="commands.italic"
              >
                <i class="fas fa-italic"></i>
              </vs-button>
              <vs-button
                :class="{ 'is-active': isActive.strike() }"
                @click="commands.strike"
              >
                <i class="fas fa-strikethrough"></i>
              </vs-button>

              <vs-button
                :class="{ 'is-active': isActive.underline() }"
                @click="commands.underline"
              >
                <i class="fas fa-underline"></i>
              </vs-button>

              <vs-button
                :class="{ 'is-active': isActive.paragraph() }"
                @click="commands.paragraph"
              >
                <i class="fas fa-paragraph"></i>
              </vs-button>

              <vs-button
                :class="{ 'is-active': isActive.heading({ level: 1 }) }"
                @click="commands.heading({ level: 1 })"
              >
                <i class="fas fa-heading"></i>1
              </vs-button>

              <vs-button
                :class="{ 'is-active': isActive.heading({ level: 2 }) }"
                @click="commands.heading({ level: 2 })"
              >
                <i class="fas fa-heading"></i>2
              </vs-button>

              <vs-button
                :class="{ 'is-active': isActive.heading({ level: 3 }) }"
                @click="commands.heading({ level: 3 })"
              >
                <i class="fas fa-heading"></i>3
              </vs-button>

              <vs-button
                :class="{ 'is-active': isActive.bullet_list() }"
                @click="commands.bullet_list"
              >
                <i class="fas fa-list-ul"></i>
              </vs-button>

              <vs-button
                :class="{ 'is-active': isActive.ordered_list() }"
                @click="commands.ordered_list"
              >
                <i class="fas fa-list-ol"></i>
              </vs-button>

              <!-- <vs-button
                :class="{ 'is-active': isActive.blockquote() }"
                @click="commands.blockquote"
              >
                <i class="fas fa-quote-right"></i>
              </vs-button> -->

              <vs-button @click="showImagePrompt(commands.image)">
                <i class="fas fa-image"></i>
              </vs-button>
              <vs-button
                class="menububble__vs-button"
                @click="showLinkMenu(commands.link, getMarkAttrs('link'))"
                :class="{ 'is-active': isActive.link() }"
              >
                <i class="fas fa-link"></i>
              </vs-button>

              <vs-button @click="commands.undo">
                <i class="fas fa-undo"></i>
              </vs-button>

              <vs-button @click="commands.redo">
                <i class="fas fa-redo"></i>
              </vs-button>
            </vs-button-group>
          </div>
        </editor-menu-bar>
        <editor-content class="editorTextarea" :editor="editor" />
      </div>

      <template #footer>
        <div class="dialogActionBtns">
          <vs-button @click="showDoctor = false" danger transparent>
            Cancelar
          </vs-button>
          <vs-button @click="onSave" transparent success>
            Guardar
          </vs-button>
        </div>
      </template>
    </vs-dialog>

    <vs-dialog blur v-model="showUser">
      <template #header>
        <div style="width:100%;">
          <h4 class="not-margin">
            <b>{{ currentUser.displayName }}</b>
          </h4>
        </div>
        <b-form-file
          ref="avatarInput"
          class="inputAvatar"
          :state="Boolean(true)"
          accept="image/*"
          @change="onUploadFile($event, 'user')"
        />
        <vs-avatar badge-position="bottom-left" badge size="80">
          <img
            ref="avatarImageSrcUser"
            :src="
              currentUser.imageUrl ||
                'https://www.coprivado.com.ar/panel_control/files/novedades/194_avatar.png'
            "
            alt=""
          />
          <template #badge>
            <vs-button icon size="mini" @click="onChangeImg($event, 'user')">
              <i class="fas fa-pencil-alt"></i>
            </vs-button>
          </template>
        </vs-avatar>
      </template>

      <div class="con-form">
        <vs-switch
          class="inputForm"
          color="#7d33ff"
          v-model="currentUser.gender"
        >
          <template #off>
            Hombre
          </template>
          <template #on>
            Mujer
          </template>
          <template #circle>
            <i v-if="!currentUser.gender" class="fas fa-mars"></i>
            <i v-else class="fas fa-venus"></i>
          </template>
        </vs-switch>
        <vs-input
          class="inputForm"
          type="date"
          v-model="currentUser.birthDate"
          label="Fecha de nacimiento:"
        >
          <!-- <template #icon>
            <i class="far fa-calendar-alt"></i>
          </template> -->
        </vs-input>
        <vs-input
          class="inputForm"
          type="number"
          icon-after
          v-model="currentUser.phone"
          label="Teléfono de contacto:"
          placeholder="Introduce tu número de teléfono:"
        >
          <template #icon>
            <i class="fas fa-phone-alt"></i>
          </template>
        </vs-input>
        <div
          v-for="(history, index) in currentUser.medicalHistory"
          :key="'history_' + index"
        >
          <vs-input
            class="inputForm lastinput"
            type="text"
            icon-after
            v-model="currentUser.medicalHistory[index]"
            :label="index > 0 ? '' : 'Antecedentes médicos:'"
            placeholder="Introduce tus antecedentes médicos:"
          >
            <template #icon>
              <i class="fas fa-comment-medical"></i>
            </template>
          </vs-input>
          <div class="removeBtn">
            <vs-button @click="removeMedicalHistory(index)" danger size="mini">
              Eliminar
            </vs-button>
          </div>
        </div>

        <div class="dialogActionBtns">
          <vs-button @click="addMedicalHistory" class="addBtn" size="mini">
            Añadir antecedentes
          </vs-button>
        </div>

        <div class="switchersNotif">
          <div class="dialogActionBtns">
            <vs-switch
              :success="currentUser.enableMail"
              :danger="!currentUser.enableMail"
              v-model="currentUser.enableMail"
            >
              <template #off>
                <i class="fas fa-ban"></i>
              </template>
              <template #on>
                <i class="fas fa-envelope"></i>
              </template>
            </vs-switch>
            <div>Correo Electrónico</div>
          </div>

          <div class="dialogActionBtns">
            <vs-switch
              :success="currentUser.enablePush"
              v-model="currentUser.enablePush"
            >
              <template #off>
                <i class="fas fa-bell-slash"></i>
              </template>
              <template #on>
                <i class="fas fa-bell"></i>
              </template>
            </vs-switch>
            <div>Notificaciones en la App</div>
          </div>
        </div>
      </div>

      <template #footer>
        <div class="dialogActionBtns">
          <vs-button @click="showUser = false" danger transparent>
            Cancelar
          </vs-button>
          <vs-button @click="onSave" transparent success>
            Guardar
          </vs-button>
        </div>
      </template>
    </vs-dialog>
  </div>
</template>

<script>
import { authMix, patientMix } from "@/mixins/mixins.js";
import { mapState } from "vuex";
import { Editor, EditorContent, EditorMenuBar } from "tiptap";
import LineChart from "@/components/Charts/line.js";

import {
  Blockquote,
  HardBreak,
  Heading,
  OrderedList,
  BulletList,
  ListItem,
  TodoItem,
  TodoList,
  Bold,
  Image,
  Italic,
  Link,
  Strike,
  Underline,
  History,
} from "tiptap-extensions";

export default {
  name: "ProfilePage",
  mixins: [authMix, patientMix],
  components: {
    EditorContent,
    EditorMenuBar,
    LineChart,
  },
  data() {
    return {
      currentUser: {},
      copyUser: {},
      isExternal: true,
      showDoctor: false,
      showUser: false,
      imgLoading: false,
      previewImgFile: null,
      editor: null,
      description: "",
      interventions: [],
      selectedIntervention: "",
      datacollection: {},
      loaded: false,
      options: {
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
                min: 0, // minimum value
                max: 100, // maximum value
              },
            },
          ],
        },
        responsive: true,
        maintainAspectRatio: true,
      },
    };
  },
  methods: {
    getYears(birdthDate) {
      const originDate = new Date(birdthDate).getFullYear();
      const today = new Date().getFullYear();
      return birdthDate ? today - originDate : 30;
    },

    addMedicalHistory() {
      this.currentUser.medicalHistory.push("");
    },

    removeMedicalHistory(ind) {
      this.currentUser.medicalHistory.splice(ind, 1);
    },

    async getInitData() {
      const user =
        this.$store.state.currentUserInfo.user ||
        this.$store.state.currentUserInfo;
      const { displayName, email, uid, photoURL } = user;
      const userData = await this.firebaseGetUser(uid);
      this.interventions = await this.getInterventions(uid);

      if (userData && userData.id) {
        this.description = userData.description;
        this.currentUser = userData;
        this.currentUser.imageUrl = photoURL;
        this.currentUser.email = email || "";
        this.currentUser.displayName = displayName || "Nombre Demo";
        this.copyUser = Object.assign({}, this.currentUser);
        this.createEditor(false);
      }
    },

    createEditor(editable) {
      const extensions = [
        new Blockquote(),
        new HardBreak(),
        new Heading({ levels: [1, 2, 3] }),
        new BulletList(),
        new OrderedList(),
        new ListItem(),
        new TodoItem(),
        new TodoList(),
        new Bold(),
        new Image(),
        new Italic(),
        new Link(),
        new Strike(),
        new Underline(),
        new History(),
      ];
      this.editor = new Editor({
        extensions,
        content: this.description,
        editable,
      });
    },

    showImagePrompt(command) {
      const src = prompt("Enter the url of your image here");
      if (src !== null) {
        command({ src });
      }
    },

    showLinkMenu(command, attrs) {
      command({ href: attrs.href });
    },

    onEdit() {
      if (this.currentUser.isDoctor) {
        this.editor.destroy();
        this.createEditor(true);
        this.showDoctor = true;
      } else {
        this.showUser = true;
      }
    },

    async onSave() {
      const copyUser = Object.assign({}, this.currentUser);
      if (this.previewImgFile && copyUser.id) {
        await this.firebaseSetProfileImg(copyUser.id, this.previewImgFile);
        let user =
          this.$store.state.currentUserInfo.user ||
          this.$store.state.currentUserInfo;
        user = Object.assign({}, user);
        user.photoURL = this.currentUser.imageUrl;
        this.$store.commit("setUserInfo", user);
      }

      delete copyUser.email;
      delete copyUser.displayName;
      delete copyUser.imageUrl;
      if (this.currentUser.isDoctor) {
        const desc = this.editor.getHTML();
        copyUser.description = desc;
        await this.firebaseSaveUser(copyUser.id, copyUser);
        this.currentUser.description = desc;
        this.showDoctor = false;
        this.description = desc;
        this.editor.destroy();
        this.createEditor(false);
      } else {
        await this.firebaseSaveUser(copyUser.id, copyUser);
        this.showUser = false;
      }
    },

    onChangeImg(oEvent) {
      this.imgLoading = true;
      oEvent.stopPropagation();
      oEvent.cancelBubble = true;
      this.$refs.avatarInput.$el.children[0].click();
    },

    onUploadFile(oEvent, type) {
      const image = oEvent.target.files[0];
      this.previewImgFile = image;

      if (image) {
        const reader = new FileReader();
        reader.readAsDataURL(image);
        reader.onload = (oEvent2) => {
          this.currentUser.imageUrl = oEvent2.target.result;
          const ref =
            type === "dr"
              ? this.$refs.avatarImageSrc
              : this.$refs.avatarImageSrcUser;
          ref.src = oEvent2.target.result;
          this.openNotification("top-right", "success");
        };
      }
      this.imgLoading = false;
      oEvent.stopPropagation();
      oEvent.cancelBubble = true;
    },

    openNotification(position = null, color) {
      this.$vs.notification({
        color,
        position,
        title: "Cambiado con exito",
        text: "Se ha actualizado la imagen con exito",
      });
    },

    loadChart() {
      const data = Object.assign({}, this.datacollection);
      const currentInterv = this.interventions.filter(
        (inter) => inter.id === this.selectedIntervention
      )[0];
      let labels = [],
        points = [];
      currentInterv.results.forEach((res) => {
        labels.push(res.name);
        if (res.points) {
          let pts = res.points_dr ? res.points + res.points_dr : res.points;
          points.push(pts);
        } else {
          let pts = res.points_dr ? 0 + res.points_dr : 0;
          points.push(pts);
        }
      });

      data.labels = labels;
      data.datasets[0].data = points;
      this.datacollection = data;
      this.loaded = true;
    },

    fillData() {
      this.datacollection = {
        labels: [],
        datasets: [
          {
            label: "Evolución",
            backgroundColor: "#17a2b8aa",
            data: [],
          },
        ],
      };
    },
  },
  mounted() {
    this.getInitData();
    this.fillData();
  },
  beforeDestroy() {
    this.editor.destroy();
  },
  computed: {
    filteredIntervention() {
      return this.interventions.filter(
        (inter) => inter.id === this.selectedIntervention
      );
    },
    ...mapState(["currentUserInfo"]),
  },
  watch: {
    currentUserInfo(newValue) {
      if (newValue.user) {
        this.getInitData();
      }
    },
  },
};
</script>

<style scoped>
.container {
  text-align: justify;
  padding: 4rem 1rem 1rem 4.5rem;
}

.dialogActionBtns,
.switchDisabled {
  display: flex;
  justify-content: space-between;
}

.switchDisabled {
  justify-content: start;
}

.switchersNotif {
  padding: 1rem;
}

.switchersNotif > div {
  padding: 0.5rem;
}

.userInfo {
  text-align: start;
}

.inputForm {
  margin: 2rem 1rem;
}

.inputForm.lastinput {
  margin: 0.5rem;
}

.addBtn {
  margin: 0 0 0 1rem;
}

.removeBtn {
  display: flex;
  justify-content: flex-end;
}

.avatar > div {
  margin: auto;
}

.inputAvatar {
  display: none;
}

.editorTextarea {
  padding: 1rem;
  border: 1px solid;
  border-radius: 0 0 2rem 2rem;
  border-top: none;
  box-shadow: 2px 3px 20px 0px #6c757d;
}
</style>
<style>
.inputForm > div > input {
  width: 100%;
}
</style>
